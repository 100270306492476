@use './../variables' as *;
@use './../mixins' as *;

.mdc-button {
  font-weight: 400;
  &.mat-mdc-button-base {
    &.mat-primary:not(.mat-button-disabled) {
      &:hover,
      &.focus {
        background-color:$xpo-grey--975;
      }
    }
  }
}

@mixin hugeButton {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  height: 56px;
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
}
