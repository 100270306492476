@use 'variables' as *;
@use './styles/material' as *;
@use './styles/utils' as *;

// TODO: Move to library, Ancel (very very future)
// To improve light house score rather than linking in <head>
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.woff2') format('woff2');
  display: swap;
  font-weight: 100 400;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.woff2') format('woff2');
  display: swap;
  font-weight: 500 900;
}

html, body {
  margin: 0;
  overscroll-behavior-y: contain; //Avoids page refresh when pulling page down
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  font-family: $xpo-fontFamily--primary;
}

h1,h2,h3,h4,h5,h6 {
  text-wrap:balance;
  font-weight: 400;
  margin: 0;
}

h1,h2,h3,h4,h5,h6, span, p, a, table, text, svg {
  font-family: $xpo-fontFamily--primary;
}

a {
  text-decoration-color: $xpo-blue--800;
  color: $xpo-blue--800;
  cursor: pointer;
}
