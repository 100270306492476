@use 'variables'as *;
@use 'button' as *;

mat-dialog-container.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    padding: $rem $rem * 2 0;
  }

  .mat-mdc-dialog-content {
    color: $xpo-grey--900;
    font-size: $xpo-fontSize--extraLarge;
    line-height: $xpo-lineHeight--lg;
    padding: 0;
  }

  .mat-mdc-dialog-actions {
    border-top: 1px solid $xpo-grey--300;
    padding: $xpo-contentSpacing * 2 0;

    .mat-mdc-button-base {
      @include hugeButton();
    }
  }
}
