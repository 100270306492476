@use './../variables' as *;
@use './../mixins' as *;

.exDev-Utils-hero {
  min-height: 400px;
  color: $xpo-white;
  position: relative;
  display: grid;
  place-content: center flex-start;
  padding: 0 7%;
  &-overlay {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
  &-img {
    object-fit: cover;
    width: 100%;
    position: absolute;
    inset: 0;
    height: 100%;
    z-index: 1;
  }
  &-body {
    padding: $rem 0;
    z-index: 5;
  }
  &-title {
    font-size: 3rem;
    margin: 0;
    font-weight: 400;
  }
  &-subtitle {
    font-size: 1.8rem;
    margin: $rem 0 0 0;
    font-weight: 400;
  }
  &-actionButton {
    min-height: 3.5 * $rem;
    font-size: 1.4rem;
    margin: 2*$rem 0 0 0;
  }
}
@include media-screen-range-0_sm {
  .exDev-Utils-hero {
    &-title {
      font-size: 2rem;
    }
    &-subtitle {
      font-size: 1.2rem;
    }
    &-actionButton {
      min-height: 2.2 * $rem;
      font-size: $rem;
      margin: 2*$rem 0 0 0;
    }
    &-body {
      text-align: center;
    }
  }
}
