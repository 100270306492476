@use './../variables' as *;
@use './../mixins' as *;
@use './../breakpoints' as *;

.exDev-Utils-page {
  &-centerContent {
    width: fit-content;
    margin: 0 auto;
    display: grid;
    gap: $rem;
    &--flexEnd {
      place-items: flex-end;
    }
  }
  &-mid {
    width: min(60vw, $lt-md);
    margin: 0 auto;
    display: grid;
    padding: $rem 0;
  }
}

@include media-screen-range-0_md {
  .exDev-Utils-page {
    &-mid {
      width: 90vw;
    }
  }
}
