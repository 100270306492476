@use './../variables' as *;
@use './../mixins' as *;

.exDev-Utils-text {
  &-navMenu {
    font-size: $xpo-fontSize--medium;
    color: $xpo-grey--900;
  }
  &-sectionTitle {
    font-size: 2rem;
    text-align: center;
    margin: 3*$rem 0;
  }

  &-h {
    &3 {
      margin: 0;
      font-size: 1.4rem;
    }
  }

  &-body {
    font-size: $xpo-fontSize--medium;
  }
}

@include media-screen-range-0_sm {
  .exDev-Utils-text {
    &-sectionTitle {
      font-size: 1.4rem;
      margin: $rem 0;
    }
  }
}
