@use 'variables' as *;

mat-form-field,
.mat-mdc-form-field {
  width: 100%;
  padding-top: $remh;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  // Define foccused style inside formfield
  .mdc-text-field--focused {
    .mdc-notched-outline {
      .mdc-notched-outline__notch, .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
        border-color: $xpo-black ;
      }
      .mdc-notched-outline__notch {
        label.mdc-floating-label, label.mat-mdc-floating-label  {
          color: $xpo-black ;
        }
      }
    }
  }

  .mat-mdc-form-field-required-marker {
    color: $xpo-brand-red;
  }
}
