@use 'breakpoints' as *;
@use './variables' as *;

@mixin media-screen-min-sm {
  @media only screen and (min-width: $lt-sm) {
    @content;
  }
}
@mixin media-screen-min-md {
  @media only screen and (min-width: $lt-md) {
    @content;
  }
}
@mixin media-screen-min-lg {
  @media only screen and (min-width: $lt-lg) {
    @content;
  }
}
@mixin media-screen-min-xl {
  @media only screen and (min-width: $lt-xl) {
    @content;
  }
}
@mixin media-screen-range-0_sm {
  @media only screen and (min-width: 0) and (max-width: $lt-sm) {
    @content;
  }
}
@mixin media-screen-range-0_md {
  @media only screen and (min-width: 0) and (max-width: $lt-md) {
    @content;
  }
}
@mixin media-screen-range-sm_md {
  @media only screen and (min-width: $lt-sm) and (max-width: $lt-md) {
    @content;
  }
}
@mixin media-screen-range-sm_lg {
  @media only screen and (min-width: $lt-sm) and (max-width: $lt-lg) {
    @content;
  }
}
@mixin media-screen-range-sm_xl {
  @media only screen and (min-width: $lt-sm) and (max-width: $lt-xl) {
    @content;
  }
}
@mixin media-screen-range-md_xl {
  @media only screen and (min-width: $lt-md) and (max-width: $lt-xl) {
    @content;
  }
}
@mixin media-screen-range-lg_xl {
  @media only screen and (min-width: $lt-lg) and (max-width: $lt-xl) {
    @content;
  }
}


@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


@mixin exDevUtilsGrid($columns: null, $gap: $rem) {
  display: grid;
  grid-template-columns: $columns;
  gap: $gap;
}
