@forward './legacyVariables';


$rem: 1rem;
$remh: 0.5 * 1rem;
$remq: 0.25 * 1rem;

$materialFontFamily: 'Material Icons';

$greenCheck: #6AAC5B;

